@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.container {
  width: 480px;
  min-height: 330px;
  padding: 16px;

  h2 {
    margin: 0;
  }

  p {
    min-height: 105px;
  }

  h3 {
    margin: 8px 0px;
  }
}

.membersRow {
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;

  >div {
    margin-right: 4px;
  }
}

.lastLine {
  display: flex;
  justify-content: space-between;
  gap: 12px;

  @media (max-width: $tablet) {
    flex-direction: column;
  }

  .btnContainer {
    display: flex;
  }
}