@import "styles/colors.scss";

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px;
    padding-top: 80px;

    .board {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        justify-content: center;
        padding: 8px 0px;

        > div {
            margin: 8px 8px;
        }
    }

}
