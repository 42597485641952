@import "styles/colors.scss";

div[role=tooltip] {

  >div {
    font-size: 1rem;
  }
}

.icon {
  width: 36px;
  height: 36px;
  background-color: $inactive;

  &.art {
    background-color: $art;
  }

  &.audio {
    background-color: $audio;
  }

  &.code {
    background-color: $code;
  }

  &.gameDesign {
    background-color: $gameDesign;
  }

  &.writing {
    background-color: $writing;
  }
}