@import "styles/colors.scss";
@import "styles//breakpoints.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin: 0px 16px;
  max-width: 1000px;
  width: 100%;

  @media (max-width: $tablet) {
    flex-direction: column;
  }

  .button {
    width: 160px;
    height: 40px;
    margin-right: 80px;

    @media (max-width: $tablet) {
      margin-right: 0;
    }
  }

  h3 {
    margin-right: 12px;
  }
}