@import "styles/colors.scss";

.container {
  position: fixed;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: #000000AA;

  svg {
    position: fixed;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    stroke: $secondary;
  }
}