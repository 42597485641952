$primary: #FAB31B;
$primary-light: #fbca34;
$secondary: #1B62FA;
$secondary-light: #00a9ff;

$background: #FEDCAF;
$inactive: #959595;

//roles colors
$art: #F65FFA;
$audio: #7944E3;
$code: #5788FA;
$gameDesign: #44D6E3;
$writing: #4DFF96;
