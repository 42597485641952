@import "styles/global.scss";
@import "styles/colors.scss";

.container {
  z-index: 1000;
  width: 100%;
  height: $navbarHeight;
  top: 0;
  background-color: $primary;
  position: fixed;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  padding: 0 32px;
  align-items: center;
  justify-content: space-between;

  img {
    height: 48px;
  }

  .btn {
    width: 60px;
    height: 60px;

    .avatar {
      width: 48px;
      height: 48px;
      background-color: $secondary;
    }
  }


}